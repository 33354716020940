import * as types from './mutation-types'

export default {
  [types.SET_USER_DATA] (state, userData) {
    state.userData = userData
  },
  [types.SET_USER_ROLE] (state, role) {
    state.role = role
  },
  [types.SET_HAS_MENTOR_ACCOUNT] (state, hasMentorAccount) {
    state.hasMentorAccount = hasMentorAccount
  },
  [types.SET_HAS_MENTEE_ACCOUNT] (state, hasMenteeAccount) {
    state.hasMenteeAccount = hasMenteeAccount
  },
  [types.SET_HAS_ADMIN_ACCOUNT] (state, hasAdminAccount) {
    state.hasAdminAccount = hasAdminAccount
  },
  [types.SET_AUTHENTICATION_CHECK_DONE] (state, done) {
    state.authenticationCheckDone = done
  }
}
