import * as types from './mutation-types'

export default {
  [types.SET_MENTEES_LIST] (state, menteesList) {
    state.list = menteesList
  },
  [types.SET_MENTEE] (state, mentee) {
    let index = state.list.findIndex(item => item.id === mentee.id)
    if(index !== -1) {
      state.list[index] = mentee
    }
  },
}
