export default [
  {
    path: '/mentee/mentorships',
    name: 'mentee-mentorships',
    component: () => import('@mentoring-platform/views/pages/Mentorships.vue'),
    meta: {
      action: 'read',
      resource: 'mentee'
    },
  },
  {
    path: '/mentee/my-account',
    name: 'mentee-my-account',
    component: () => import('@mentoring-platform/views/pages/MyAccount.vue'),
    meta: {
      action: 'read',
      resource: 'mentee'
    }
  },
  {
    path: '/checkout/:id',
    name: 'checkout',
    component: () => import('@mentoring-platform/views/pages/Checkout.vue'),
    meta: {
      action: 'read',
      resource: 'mentee'
    }
  },
  {
    path: '/purchases',
    name: 'purchase-history',
    component: () => import('@mentoring-platform/views/pages/PurchaseHistory.vue'),
    meta: {
      action: 'read',
      resource: 'mentee'
    }
  }
]
