import axios from '@axios'
import ability from '@/libs/acl/ability'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL
axios.defaults.withCredentials = true

const httpClient = {

  login(payload) {
    return axios.get('/sanctum/csrf-cookie')
      .then(() => axios.post('api/login', payload))
  },

  changePassword(payload) {
    return axios.post('api/client/user/change-password', payload)
  },

  forgotPassword(payload) {
    return axios.post('api/forgot-password', payload)
  },

  resetPassword(payload) {
    return axios.post('api/reset-password', payload)
  },

  logout() {
    return axios.post('api/logout')
  },

  mentorRegister(payload) {
    return axios.post('api/client/mentor/mentors', payload)
  },

  mentorEdit(payload) {
    if(ability.can('read', 'mentor')) {
      return axios.put('api/client/mentor/mentors/' + payload.mentor_id, payload)
    }
    else if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentors/' + payload.mentor_id, payload)
    }
    return Promise.reject();
  },

  mentorEditStatus(payload) {
    return axios.put('api/client/mentor/mentors/' + payload.mentor_id + '/change-status', payload)
  },

  menteeCreateMentorProfile(payload) {
    return axios.post('api/client/mentee/mentors', payload)
  },

  menteeRegister(payload) {
    return axios.post('api/client/mentee/mentees', payload)
  },

  menteeEdit(payload) {
    if(ability.can('read', 'mentee')) {
      return axios.put('api/client/mentee/mentees/' + payload.mentee_id, payload)
    }
    else if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentees/' + payload.mentee_id, payload)
    }
    return Promise.reject();
  },

  mentorCreateMenteeProfile(payload) {
    return axios.post('api/client/mentor/mentees', payload)
  },

  fetchUserData() {
    return axios.get('api/user/me')
  },

  fetchWorkStatuses() {
    return axios.get('api/client/user/work-statuses')
  },

  fetchIndustries() {
    return axios.get('api/client/user/industries')
  },

  fetchUserReferrals() {
    return axios.get('api/client/user/user-referrals')
  },

  fetchFieldsOfSupport() {
    return axios.get('api/client/user/fields-of-support')
  },

  fetchCounties() {
    return axios.get('api/client/user/counties')
  },

  fetchCountries() {
    return axios.get('api/client/user/countries')
  },

  fetchMentors() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors')
    }
    return axios.get('api/client/user/mentors')
  },

  searchMentors(payload) {
    return axios.get('api/client/user/mentors/search', { params: payload })
  },

  fetchMentees() {
    return axios.get('api/admin/mentees')
  },


  fetcAvailableSubscriptions() {
    return axios.get('api/client/user/available-subscriptions');
  },

  checkEmail(email) {
    return axios.get('api/client/user/check-email', {
      params: {
        email: email
      }
    })
  },

  chooseAccount(payload) {
    return axios.post('api/client/user/choose-account', payload)
  },

  switchAccount(payload) {
    return axios.post('api/client/user/switch-account', payload)
  },

  purchaseSubscription(payload) {
    return axios.post('api/client/mentee/purchase-subscription/' + payload.id)
  },

  fetchMenteeSubscriptions(payload) {
    if(ability.can('read', 'mentee')) {
      return axios.get('api/client/mentee/purchased-subscriptions')
    }
    else if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentees/' + payload.id + '/purchased-subscriptions')
    }
    return Promise.reject();
  },

  fetchMenteeSubscriptionPackages() {
    return axios.get('api/client/mentee/purchased-subscriptions/packages')
  },

  requestMentoring(payload) {
    return axios.post('api/client/mentee/request-mentoring', payload)
  },

  requestCoaching(payload) {
    return axios.post('api/client/mentee/request-coaching', payload)
  },

  fetchMentorships() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentorships')
    }
    return Promise.reject();
  },

  stopMentorship(payload) {
    if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentorships/' + payload.id + '/stop')
    }
    return Promise.reject();
  },

  completeMentorship(payload) {
    if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentorships/' + payload.id + '/complete')
    }
    return Promise.reject();
  },

  fetchMentorshipRequests(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentorship-requests', { params: payload })
    }
    return Promise.reject();
  },

  fetchMentorshipRequestsOfMentor(payload) {
    if(ability.can('read', 'mentor')) {
      return axios.get('api/client/mentor/mentorship-requests')
    }
    else if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/' + payload.id + '/mentorship-requests')
    }
    return Promise.reject();
  },

  fetchMentorshipRequestsOfMentee(payload) {
    if(ability.can('read', 'mentee')) {
      return axios.get('api/client/mentee/mentorship-requests')
    }
    else if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentees/' + payload.id + '/mentorship-requests')
    }
    return Promise.reject();
  },

  handleMentorshipRequestStatus(payload) {
    if(ability.can('read', 'mentor')) {
      return axios.put('api/client/mentor/mentorship-requests', payload)
    }
    else if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentors/' + payload.mentor_id + '/mentorship-requests', payload)
    }
    return Promise.reject();
  },

  fetchCurrentMentorship() {
    if(ability.can('read', 'mentor')) {
      return axios.get('api/client/mentor/current-mentorship')
    }
    else if(ability.can('read', 'mentee')) {
      return axios.get('api/client/mentee/current-mentorship')
    }
    return Promise.reject();
  },

  fetchCurrentMentorshipForMentor(payload) {
    return axios.get('api/admin/mentors/' + payload.id + '/current-mentorship')
  },

  fetchCurrentMentorshipForMentee(payload) {
    return axios.get('api/admin/mentees/' + payload.id + '/current-mentorship')
  },

  increaseCompletedSessions(payload) {
    if(ability.can('read', 'mentee')) {
      return axios.put('api/client/mentee/current-mentorship/completed-sessions/increase')
    }
    else if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentees/' + payload.id + '/current-mentorship/completed-sessions/increase')
    }
    return Promise.reject();
  },

  decreaseCompletedSessions(payload) {
    if(ability.can('read', 'mentee')) {
      return axios.put('api/client/mentee/current-mentorship/completed-sessions/decrease')
    }
    else if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentees/' + payload.id + '/current-mentorship/completed-sessions/decrease')
    }
    return Promise.reject();
  },

  fetchPreviousMentorshipsOfMentor(payload) {
    if(ability.can('read', 'mentor')) {
      return axios.get('api/client/mentor/previous-mentorships')
    }
    else if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/' + payload.id + '/previous-mentorships')
    }
    return Promise.reject();
  },

  fetchMentorApplications() {
    return axios.get('api/admin/mentors/applications')
  },

  approveMentorApplication(payload) {
    return axios.put('api/admin/mentors/applications', payload)
  },

  rejectMentorApplication(payload) {
    return axios.put('api/admin/mentors/applications', payload)
  },

  sendMessageToMentor(payload) {
    if(ability.can('read', 'admin')) {
      return axios.post('api/admin/mentors/' + payload.mentor_id + '/messages', payload)
    }
    return Promise.reject();
  },

  fetchMentorMessages(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/' + payload.id + '/messages')
    }
    return Promise.reject();
  },

  sendMessageToMentee(payload) {
    if(ability.can('read', 'admin')) {
      return axios.post('api/admin/mentees/' + payload.mentee_id + '/messages', payload)
    }
    return Promise.reject();
  },

  fetchMenteeMessages(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentees/' + payload.id + '/messages')
    }
    return Promise.reject();
  },

  fetchPendingEditRequests() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/pending-edit-requests')
    }
    return Promise.reject();
  },

  fetchPendingEditRequestsOfMentor(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/' + payload.mentor_id + '/pending-edit-requests')
    }
    return Promise.reject();
  },

  handlePendingEditRequest(payload) {
    if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentors/pending-edit-requests/' + payload.id, payload)
    }
    return Promise.reject();
  },

  fetchTestimonials(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/' + payload.mentor_id + '/testimonials')
    }
    else {
      return axios.get('api/client/user/mentors/' + payload.mentor_id + '/testimonials')
    }
  },

  fetchMyTestimonials() {
    return axios.get('api/client/mentor/testimonials')
  },

  createTestimonial(payload) {
    return axios.post('api/admin/mentors/' + payload.mentor_id + '/testimonials', payload)
  },

  editTestimonial(payload) {
    return axios.put('api/admin/mentors/testimonials/' + payload.testimonial_id, payload)
  },

  deleteTestimonial(payload) {
    return axios.delete('api/admin/mentors/testimonials/' + payload.testimonial_id)
  },

  downloadMentorsExport() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentors/export', { responseType: 'blob' })
    }
    return Promise.reject();
  },

  downloadMenteesExport() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentees/export', { responseType: 'blob' })
    }
    return Promise.reject();
  },

  fetchScholarships() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentees/scholarships')
    }
    return Promise.reject();
  },

  createScholarship(payload) {
    if(ability.can('read', 'admin')) {
      return axios.post('api/admin/mentees/scholarships', payload)
    }
    return Promise.reject();
  },

  editScholarship(payload) {
    if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentees/scholarships/' + payload.scholarship_id, payload)
    }
    return Promise.reject();
  },

  fetchScholarshipsOfMentee(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/mentees/' + payload.id + '/scholarships')
    }
    return Promise.reject();
  },

  giveScholarshipToMentee(payload) {
    if(ability.can('read', 'admin')) {
      return axios.put('api/admin/mentees/' + payload.mentee_id + '/scholarships', payload)
    }
    return Promise.reject();
  },

  removeScholarshipFromMentee(payload) {
    if (ability.can('read', 'admin')) {
      return axios.delete('api/admin/mentees/scholarships/subscriptions/' +  payload.subscription_id)
    }
    return Promise.reject()
  },

  fetchPurchasedSubscriptions() {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/purchased-subscriptions')
    }
    return Promise.reject();
  },

  markSubscriptionAsPaid(payload) {
    if(ability.can('read', 'admin')) {
      return axios.put('api/admin/purchased-subscriptions/' + payload.id + '/mark-as-paid')
    }
    return Promise.reject();
  },

  fetchAvailableMentorsForSubscriptionPackage(payload) {
    if(ability.can('read', 'admin')) {
      return axios.get('api/admin/purchased-subscriptions-packages/' + payload.id + '/available-mentors')
    }
    return Promise.reject();
  },

  createMentorshipForSubscriptionPackage(payload) {
    if(ability.can('read', 'admin')) {
      return axios.post('api/admin/purchased-subscriptions-packages/' + payload.id + '/create-mentorship', { mentor_id: payload.mentor_id })
    }
    return Promise.reject();
  },

  changeLocale(payload) {
    return axios.post('api/client/user/change-locale', payload)
  },

}

export default httpClient
