import * as types from './mutation-types'
import httpClient from '@mentoring-platform/http/httpClient'
import { mentorStatuses } from '@/mentoring-platform/constants'

export default {
  getList({ commit }) {
    return httpClient.fetchMentors()
      .then((response) => {
        commit(types.SET_MENTORS_LIST, response.data)
        return true
      })
      .catch((error) => {
        return false
      })
  },
  getApplicationsList({ commit }) {
    return httpClient.fetchMentorApplications()
      .then((response) => {
        commit(types.SET_APPLICATIONS_LIST, response.data)
        return true
      })
      .catch((error) => {
        return false
      })
  },
  getAvailableMentorsForSubscriptionPackage({}, payload) {
    return httpClient.fetchAvailableMentorsForSubscriptionPackage(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  createMentorshipForSubscriptionPackage({}, payload) {
    return httpClient.createMentorshipForSubscriptionPackage(payload)
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })
  },
  mentorEdit({ commit }, payload) {
    return httpClient.mentorEdit(payload)
      .then(response => {
        commit(types.SET_MENTOR, response.data.result)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  approveApplication({ commit }, payload) {
    return httpClient.approveMentorApplication(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  rejectApplication({ commit }, payload) {
    return httpClient.rejectMentorApplication(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  sendMessageToMentor({ commit }, payload) {
    return httpClient.sendMessageToMentor(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  getPendingEditRequestsList({ commit }) {
    return httpClient.fetchPendingEditRequests()
      .then((response) => {
        commit(types.SET_PENDING_EDIT_REQUESTS_LIST, response.data.result)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  handlePendingEditRequest({ commit }, payload) {
    return httpClient.handlePendingEditRequest(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  downloadExport({ commit }) {
    return httpClient.downloadMentorsExport()
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  clear({ commit }) {
    commit(types.SET_MENTORS_LIST, [])
    commit(types.SET_APPLICATIONS_LIST, [])
    commit(types.SET_PENDING_EDIT_REQUESTS_LIST, [])
  }
}
