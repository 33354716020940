import state from "../mentor/state"

export default {
  userDataNotEmpty: (state) => {
    if (state.userData === null || typeof state.userData !== "object") {
      return false
    }
    const keys = Object.keys(state.userData)
    return keys.includes('name') && keys.includes('surname') && state.role !== null
  },
  isUserLoggedIn: (state, getters) => {
    return getters.userDataNotEmpty ? true : false
  },
  getUserData: (state, getters) => {
    return getters.userDataNotEmpty ? state.userData : null
  },
  getFullName: (state, getters) => {
      return getters.userDataNotEmpty ? state.userData.name + ' ' + state.userData.surname : null
  },
  getUserAvatar: (state, getters) => {
    return getters.userDataNotEmpty ? state.userData.avatar : null
  },
  getUserRole: (state) => {
    return state.role
  },
  getHasMentorAccount: (state) => {
    return state.hasMentorAccount
  },
  getHasMenteeAccount: (state) => {
    return state.hasMenteeAccount
  },
  getHasAdminAccount: (state) => {
    return state.hasAdminAccount
  },
  canSwitchToMentor: (state, getters) => {
    return getters.getHasMentorAccount && getters.getUserRole !== 'mentor'
  },
  canSwitchToMentee: (state, getters) => {
    return getters.getHasMenteeAccount  && getters.getUserRole !== 'mentee'
  },
  canSwitchToAdmin: (state, getters) => {
    return getters.getHasAdminAccount  && getters.getUserRole !== 'admin'
  },
  isAuthenticationCheckDone: (state) => {
    return state.authenticationCheckDone
  }
}
