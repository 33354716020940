import adminRoutes from './admin'
import otherRoutes from './other'
import mentorRoutes from './mentor'
import menteeRoutes from './mentee'

export default [
  ...adminRoutes,
  ...otherRoutes,
  ...mentorRoutes,
  ...menteeRoutes
]
