import * as types from './mutation-types'
import httpClient from '@mentoring-platform/http/httpClient'

export default {
  getList({ commit }) {
    return httpClient.fetchMentees()
      .then((response) => {
        commit(types.SET_MENTEES_LIST, response.data)
        return true
      })
      .catch((error) => {
        return false
      })
  },
  menteeEdit({ commit }, payload) {
    return httpClient.menteeEdit(payload)
      .then(response => {
        commit(types.SET_MENTEE, response.data.result)
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  sendMessageToMentee({ commit }, payload) {
    return httpClient.sendMessageToMentee(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  downloadExport({ commit }) {
    return httpClient.downloadMenteesExport()
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  clear({ commit }) {
    commit(types.SET_MENTEES_LIST, [])
  }
}
