export default [
  {
    path: '/mentor/mentorships',
    name: 'mentor-mentorships',
    component: () => import('@mentoring-platform/views/pages/Mentorships.vue'),
    meta: {
      action: 'read',
      resource: 'mentor'
    },
  },
  {
    path: '/mentor/my-account',
    name: 'mentor-my-account',
    component: () => import('@mentoring-platform/views/pages/MyAccount.vue'),
    meta: {
      action: 'read',
      resource: 'mentor'
    }
  }
]
