// subject can be: public, not-auth, not-mentor, not-mentee, not-admin, mentor, mentee, admin

export const initialAbility = [
  {
    action: 'read',
    subject: 'public',
  },
  {
    action: 'read',
    subject: 'not-auth',
  },
  {
    action: 'read',
    subject: 'not-mentor',
  },
  {
    action: 'read',
    subject: 'not-mentee',
  },
  {
    action: 'read',
    subject: 'not-admin',
  },
]

export const _ = undefined
