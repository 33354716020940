<template>
  <div>
    <b-modal
      id="when-hub-modal"
      ref="when-hub-modal"
      centered
      size="lg"
      :title="$t('When hub modal title')"
      :ok-title="$t('When hub modal cta')"
      ok-only
      no-close-on-backdrop
    >
      <div class="my-1">
        {{ $t('When hub modal contents') }}
      </div>
      <template #modal-footer class="d-flex justify-content-between">
        <b-button
          variant="primary"
          href="https://www.giveandfund.com/giveandfund/project/WHEN-hub-crowdfund"
          target="_blank"
          @click="$refs['when-hub-modal'].hide()"
        >
          {{ $t('When hub modal cta') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  BButton,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BModal,
  },
  data() {
    return {

    }
  },
  methods: {
    show() {
      this.$refs['when-hub-modal'].show()
    }
  }
}
</script>
