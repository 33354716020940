import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import user from '@mentoring-platform/store/user'
import mentor from '@mentoring-platform/store/mentor'
import mentee from '@mentoring-platform/store/mentee'
import subscription from '@mentoring-platform/store/subscription'
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

user.isRegistered = true
mentor.isRegistered = true
mentee.isRegistered = true
subscription.isRegistered = true

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    user,
    mentor,
    mentee,
    subscription
  },
  strict: process.env.DEV,
})
