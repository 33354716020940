import * as types from './mutation-types'

export default {
  [types.SET_MENTORS_LIST] (state, mentorsList) {
    state.list = mentorsList
  },
  [types.SET_MENTOR] (state, mentor) {
    let index = state.list.findIndex(item => item.id === mentor.id)
    if(index !== -1) {
      state.list[index] = mentor
    }
  },
  [types.SET_APPLICATIONS_LIST] (state, mentorApplicationsList) {
    state.applicationsList = mentorApplicationsList
  },
  [types.SET_PENDING_EDIT_REQUESTS_LIST] (state, pendingEditRequestsList) {
    state.pendingEditRequestsList = pendingEditRequestsList
  }
}
