import * as types from './mutation-types'
import httpClient from '@mentoring-platform/http/httpClient'

export default {
  getAvailableSubscriptionList({commit}) {
    return httpClient.fetcAvailableSubscriptions()
      .then((response) => {
        commit(types.SET_AVAILABLE_SUBSCRIPTIONS_LIST, response.data)
        return true
      })
      .catch((error) => {
        return false
      })
  }
}
