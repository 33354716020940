export const MENTOR_FILTER_IS_ACTIVE = 'isActive'
export const MENTOR_FILTER_IS_NOT_ACTIVE = 'isNotActive'
export const MENTOR_FILTER_IS_MENTOR = 'isMentor'
export const MENTOR_FILTER_IS_COACH = 'isCoach'
export const MENTOR_FILTER_IS_AVAILABLE = 'isAvailable'
export const MENTOR_FILTER_IS_NOT_AVAILABLE = 'isNotAvailable'
export const MENTOR_FILTER_IS_AVAILABLE_FOR_MENTORING = 'isAvailableForMentoring'
export const MENTOR_FILTER_IS_NOT_AVAILABLE_FOR_MENTORING = 'isNotAvailableForMentoring'
export const MENTOR_FILTER_IS_AVAILABLE_FOR_COACHING = 'isAvailableForCoaching'
export const MENTOR_FILTER_IS_NOT_AVAILABLE_FOR_COACHING = 'isNotAvailableForCoaching'
export const MENTOR_FILTER_COUNTRY = 'country'
export const MENTOR_FILTER_COUNTY = 'county'
export const MENTOR_FILTER_YEARS_OF_EXPERIENCE = 'yearsOfExperience'
export const MENTOR_FILTER_INDUSTRY = 'industry'
export const MENTOR_FILTER_FIELD_OF_SUPPORT = 'fieldOfSupport'
export const MENTOR_FILTER_SUPPORTS_GREEK = 'supportsGreek'
export const MENTOR_FILTER_SUPPORTS_ENGLISH = 'supportsEnglish'
