export default [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@mentoring-platform/views/pages/Admin/Dashboard.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/my-account',
    name: 'admin-my-account',
    component: () => import('@mentoring-platform/views/pages/MyAccount.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentors',
    name: 'admin-mentors',
    component: () => import('@mentoring-platform/views/pages/Admin/Mentors.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentors/:id',
    name: 'admin-mentor-page',
    component: () => import('@mentoring-platform/views/pages/Admin/Mentor.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/pending-applications',
    name: 'admin-pending-applications',
    component: () => import('@mentoring-platform/views/pages/Admin/PendingApplications.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/pending-applications/:id',
    name: 'admin-pending-application',
    component: () => import('@mentoring-platform/views/pages/Admin/PendingApplication.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/pending-edit-requests',
    name: 'admin-pending-edit-requests',
    component: () => import('@mentoring-platform/views/pages/Admin/PendingEditRequests.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentors/:mentor_id/pending-edit-requests',
    name: 'admin-pending-edit-requests-of-mentor',
    component: () => import('@mentoring-platform/views/pages/Admin/PendingEditRequestsOfMentor.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/create-new-mentor',
    name: 'admin-create-new-mentor',
    component: () => import('@mentoring-platform/views/pages/Admin/CreateNewMentor.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentees',
    name: 'admin-mentees',
    component: () => import('@mentoring-platform/views/pages/Admin/Mentees.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentees/:id',
    name: 'admin-mentee-page',
    component: () => import('@mentoring-platform/views/pages/Admin/Mentee.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentor-suggestion-requests',
    name: 'admin-mentor-suggestion-requests',
    component: () => import('@mentoring-platform/views/pages/Admin/MentorSuggestionRequests.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/purchased-subscriptions',
    name: 'admin-purchased-subscriptions',
    component: () => import('@mentoring-platform/views/pages/Admin/PurchasedSubscriptions.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/create-new-mentee',
    name: 'admin-create-new-mentee',
    component: () => import('@mentoring-platform/views/pages/Admin/CreateNewMentee.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/scholarships',
    name: 'admin-scholarships-management',
    component: () => import('@mentoring-platform/views/pages/Admin/Scholarships.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/mentorships',
    name: 'admin-mentorships',
    component: () => import('@mentoring-platform/views/pages/Admin/Mentorships.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/pending-mentorship-requests',
    name: 'admin-pending-mentorship-requests',
    component: () => import('@mentoring-platform/views/pages/Admin/PendingMentorshipRequests.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/rejected-mentorship-requests',
    name: 'admin-rejected-mentorship-requests',
    component: () => import('@mentoring-platform/views/pages/Admin/RejectedMentorshipRequests.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  },
  {
    path: '/admin/create-new-mentorship',
    name: 'admin-create-new-mentorship',
    component: () => import('@mentoring-platform/views/pages/Admin/CreateNewMentorship.vue'),
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'admin'
    },
  }
]
