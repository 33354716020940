/* eslint-disable no-unused-vars */
import * as types from './mutation-types'
import httpClient from '@mentoring-platform/http/httpClient'
import { initialAbility } from '@/libs/acl/config'
import i18n from '@/libs/i18n'

export default {

  mentorRegister({ commit }, payload) {
    return httpClient.mentorRegister(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  mentorEdit({ commit }, payload) {
    return httpClient.mentorEdit(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  mentorEditStatus({ commit, dispatch }, payload) {
    return httpClient.mentorEditStatus(payload)
      .then(async (response) => {
        await dispatch('me')
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  menteeRegister({ commit }, payload) {
    return httpClient.menteeRegister(payload)
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })
  },
  menteeEdit({ commit, dispatch }, payload) {
    return httpClient.menteeEdit(payload)
      .then(async (response) => {
        await dispatch('me')
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  mentorCreateMenteeProfile({ commit }, payload) {
    return httpClient.mentorCreateMenteeProfile(payload)
      .then((response) => {
        return true
      })
      .catch((error) => {
        return false
      })
  },
  menteeCreateMentorProfile({ commit }, payload) {
    return httpClient.menteeCreateMentorProfile(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      httpClient.login(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit(types.SET_AUTHENTICATION_CHECK_DONE, false)
        })
    })
  },
  forgotPassword({ commit }, payload) {
    return httpClient.forgotPassword(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  resetPassword({ commit }, payload) {
    return httpClient.resetPassword(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  changePassword({ commit }, payload) {
    return httpClient.changePassword(payload)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data)
      })
  },
  setHasMentorAccount({commit}, hasMentorAccount) {
    commit(types.SET_HAS_MENTOR_ACCOUNT, hasMentorAccount)
  },
  setHasMenteeAccount({commit}, hasMenteeAccount) {
    commit(types.SET_HAS_MENTEE_ACCOUNT, hasMenteeAccount)
  },
  setHasAdminAccount({commit}, hasAdminAccount) {
    commit(types.SET_HAS_ADMIN_ACCOUNT, hasAdminAccount)
  },
  logout({ commit, dispatch }) {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    commit(types.SET_USER_DATA, {})
    commit(types.SET_HAS_MENTOR_ACCOUNT, false)
    commit(types.SET_HAS_MENTEE_ACCOUNT, false)
    commit(types.SET_HAS_ADMIN_ACCOUNT, false)
    commit(types.SET_USER_ROLE, null)
    commit(types.SET_AUTHENTICATION_CHECK_DONE, false)

    // Remove userData from localStorage
    localStorage.removeItem('userData')

    // Reset ability
    this._vm.$ability.update(initialAbility)

    dispatch('mentor/clear', null, { root: true })
    dispatch('mentee/clear', null, { root: true })

    return httpClient.logout()
      .then(response => {})
      .catch(error => {})
  },
  me({ commit, dispatch }) {
    return httpClient.fetchUserData()
      .then(async response => {
        if(response.data.currentRole == null) {
          await dispatch('logout')
          return false
        }
        commit(types.SET_USER_DATA, response.data.userData)
        commit(types.SET_USER_ROLE, response.data.currentRole)
        if(response.data.userRoles.includes('mentor')) {
          dispatch('setHasMentorAccount', true)
        }
        if(response.data.userRoles.includes('mentee')) {
          dispatch('setHasMenteeAccount', true)
        }
        if(response.data.userRoles.includes('admin')) {
          dispatch('setHasAdminAccount', true)
        }
        dispatch('saveUserAbility', response.data.currentRole)
        i18n.locale = response.data.userData.locale
        return true
      })
      .catch(error => {
        return false
      })
      .finally(() => {
        commit(types.SET_AUTHENTICATION_CHECK_DONE, true)
      })
  },
  checkIfUserIsLoggedIn({ commit, getters, dispatch }) {
    const isUserLoggedIn = getters.isUserLoggedIn
    if(isUserLoggedIn) {
      return true
    }
    const isAuthenticationCheckDone = getters.isAuthenticationCheckDone
    if(isAuthenticationCheckDone) {
      /**
       * If authentication check has been done, then if it was successfull, isUserLoggedIn will be true
       * and as result code will return before reaching this point. If it reaches here, it will mean
       * that user is not logged-in.
       */
      return false
    }
    return dispatch('me')
  },
  saveUserData({ commit }, userData) {
    commit(types.SET_USER_DATA, userData)
  },
  saveUserRole({ commit }, role) {
    commit(types.SET_USER_ROLE, role)
  },
  saveUserAbility({ commit }, accountType) {
    const newRule = {
      action: 'read',
      subject: accountType
    }
    const inversedRule = {
      action: 'read',
      subject: 'not-' + accountType
    }
    let ability = initialAbility.filter(rule => rule.subject !== inversedRule.subject).filter(rule => rule.subject !== 'not-auth')
    ability.push(newRule)
    this._vm.$ability.update(ability)
  },
  chooseAccount({ commit }, accountType){
    const payload = {
      role: accountType
    }
    return httpClient.chooseAccount(payload)
      .then(result => {
        return true
      })
      .catch(error => {
        return false
      })
  },
  switchAccount({ commit, getters, dispatch }, accountType) {
    const payload = {
      role: accountType
    }
    return httpClient.switchAccount(payload)
      .then(response => {
        const newAccountType = accountType
        dispatch('saveUserAbility', newAccountType)
        dispatch('saveUserRole', newAccountType)
        dispatch('mentor/clear', null, { root: true })
        dispatch('mentee/clear', null, { root: true })
        return true
      })
      .catch(error => {
        return false
      })
  },
  purchaseSubscription({ commit }, subscriptionId) {
    const payload = {
      id: subscriptionId
    }
    return httpClient.purchaseSubscription(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  },
  requestMentoring({ commit }, payload) {
    return httpClient.requestMentoring(payload)
      .then(response => {
        return true
      })
      .catch(error => {
        return false
      })
  },
  requestCoaching({ commit }, payload) {
    return httpClient.requestCoaching(payload)
      .then(response => {
        return true
      })
      .catch(error => {
        return false
      })
  },
  changeLocale(context, payload) {
    return httpClient.changeLocale(payload)
      .then(response => {
        i18n.locale = payload.locale
        return true
      })
      .catch(error => false)
  },
}
