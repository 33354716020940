export default {
  getList: (state) => {
    return state.list
  },
  getApplicationsList: (state) => {
    return state.applicationsList
  }, 
  getMentor: (state) => (id) => {
    return state.list.find((mentor) => mentor.id === id)
  },
  getPendingEditRequestsList: (state) => {
    return state.pendingEditRequestsList
  }, 
}
